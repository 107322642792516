export default [
  { value: '1 story', label: '1 story' },
  { value: '2 story', label: '2 story' },
  { value: '3 story', label: '3 story' },
  { value: '1 story-mid', label: '1 story-mid' },
  { value: '2 story-mid', label: '2 story-mid' },
  { value: '3 story-mid', label: '3 story-mid' },
  { value: '1 story-end', label: '1 story-end' },
  { value: '2 story-end', label: '2 story-end' },
  { value: '3 story-end', label: '3 story-end' },
];
