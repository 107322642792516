export default [
  { value: 'cmbHouse', label: 'House' },
  { value: 'cmbPositive', label: 'Positive' },
  { value: 'cmbNegative', label: 'Negative' },
  { value: 'cmbView', label: 'View' },
  { value: 'cmbMarket', label: 'Market' },
  { value: 'cmbPricing', label: 'Pricing' },
  { value: 'cmbListing', label: 'Listing' },
  { value: 'cmbExtra', label: 'Extra' },
  { value: 'txtUnique', label: 'Unique' },
  { value: 'txtSubjectComments', label: 'Subject Comments' },
  { value: 'txtNeighborhoodComments', label: 'Neighborhood Comments' },
  { value: 'txtNeighborhoodTrend', label: 'Neighborhood trends' },
  { value: 'txtUniqueComments', label: 'Unique Comments' },
  { value: 'txtMarketingStrategy', label: 'Marketing Strategy' },
  { value: 'txtDisclaimer', label: 'General Disclaimer' },
  { value: 'txtBrokerComments', label: 'Broker comments' },
  { value: 'txtValidation', label: 'Variances' },
];
