import React from 'react';

const ContractorOrders = () => {
  return (
    <div className="border-bottom-0 border p-3 bg-white">
      This is contractor orders
    </div>
  );
};

export default ContractorOrders;
