export default [
  {
    value: 'CREATE_PERMISSION',
    label: 'Create Permission',
    category: 'PERMISSIONS',
  },
  {
    value: 'UPDATE_PERMISSION',
    label: 'Update Permission',
    category: 'PERMISSIONS',
  },
  {
    value: 'DELETE_PERMISSION',
    label: 'Delete Permission',
    category: 'PERMISSIONS',
  }
];
