export default [
  { value: 'Ranch', label: 'Ranch' },
  { value: 'Colonial', label: 'Colonial' },
  { value: 'Traditional', label: 'Traditional' },
  { value: 'Contemporary', label: 'Contemporary' },
  { value: 'Bi level', label: 'Bi level' },
  { value: 'Tri level', label: 'Tri level' },
  { value: 'Cape cod', label: 'Cape cod' },
  { value: 'Bungalow', label: 'Bungalow' },
  { value: 'Cottage', label: 'Cottage' },
  { value: 'Cabin', label: 'Cabin' },
  { value: 'Victorian', label: 'Victorian' },
];
