export default [
  {
    value: 'ADD_BILLING',
    label: 'Add Billing',
    category: 'BILLINGS',
  },
  {
    value: 'SEARCH_BILLING',
    label: 'Search Billing',
    category: 'BILLINGS',
  },
  {
    value: 'DELETE_BILLING',
    label: 'Delete Billing',
    category: 'BILLINGS',
  },
  {
    value: 'UPDATE_BILLING',
    label: 'Update Billing',
    category: 'BILLINGS',
  },
];
