import styled from 'styled-components';

export const StyledContainer = styled.div`
  text-align: center;
  padding: 20px 0;
  border: 1px dashed;
  cursor: pointer;
`;

export default {};
