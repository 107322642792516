import React, { useState } from 'react';
import cogoToast from 'cogo-toast';
import { withApollo } from 'react-apollo';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import DeleteModal from 'components/DeleteModal';
import setErrorMessage from 'utils/setErrorMessage';
import DELETE_ANNOUNCEMENT from 'mutations/deleteAnnouncement';
import ALL_ANNOUNCEMENT from 'queries/allAnnouncement';

const DeleteAnnouncement = ({ announcement, client, filter }) => {
  const [isShow, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleShow = () => setShow(!isShow);

  const handleDelete = async () => {
    setLoading(true);
    try {
      const {
        data: { deleteAnnouncement },
      } = await client.mutate({
        mutation: DELETE_ANNOUNCEMENT,
        variables: {
          id: announcement.id,
        },
        refetchQueries: [
          {
            query: ALL_ANNOUNCEMENT,
            variables: { filter },
          },
        ],
      });
      setLoading(false);
      if (deleteAnnouncement)
        cogoToast.success('Announcement successfully deleted');
      else cogoToast.error(setErrorMessage());
      handleShow();
    } catch (e) {
      setLoading(false);
      cogoToast.error(setErrorMessage(e));
      handleShow();
    }
  };

  return (
    <>
      <Button variant="primary" size="sm" onClick={handleShow}>
        <FontAwesomeIcon icon={faTrash} />
      </Button>
      <DeleteModal
        show={isShow}
        isLoading={isLoading}
        onAccept={handleDelete}
        onClose={handleShow}
      />
    </>
  );
};

export default withApollo(DeleteAnnouncement);
